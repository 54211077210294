export const floorPlanLinks = [
  {
    to: "/floorplan/type-a",
    text: "1-BR Type A",
  },
  {
    to: "/floorplan/type-b",
    text: "2-BR Type B",
  },
  {
    to: "/floorplan/type-b1",
    text: "2-BR+1 Type B1",
  },
  {
    to: "/floorplan/type-b2",
    text: "2-BR+1 Type B2",
  },
  {
    to: "/floorplan/type-b3",
    text: "2-BR Type B3",
    subText: "Penthouse",
  },
  {
    to: "/floorplan/type-c",
    text: "3-BR Type C",
  },
  {
    to: "/floorplan/type-c1",
    text: "3-BR+1 Type C1",
    subText: "(With virtual tour)",
    highlight: true,
  },
  {
    to: "/floorplan/type-c2",
    text: "3-BR Type C2",
    subText: "(Premium)",
  },
  {
    to: "/floorplan/type-c3",
    text: "3-BR Type C3",
    subText: "(Premium) Penthouse",
  },
  {
    to: "/floorplan/type-d",
    text: "4-BR+1 Type D",
    subText: "Penthouse",
  },
  {
    to: "/floorplan/type-d1",
    text: "4-BR Type D1",
    subText: "(Premium) Penthouse",
  },
]
